@tailwind base;
@tailwind components;

.btn-white {
    @apply px-12 py-2 shadow-sm transition ease-in-out duration-300 rounded leading-snug whitespace-nowrap text-base font-semibold bg-white text-gray hover:bg-red hover:text-white border-2 border-white hover:border-white;
  }

.btn-red {
    @apply px-12 py-2 shadow-sm transition ease-in-out duration-300 rounded leading-snug whitespace-nowrap text-base font-semibold border-red border-2 text-red md:hover:bg-red md:hover:text-white md:hover:border-red;
  }

@tailwind utilities;